/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
        ;


        /*
		|
		| Cookie Manager
		|-----------------
		*/
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        /*
		|
		| Slider flex
		|-----------------
		*/
        var swipergallery = new Swiper('.swiper-gallery', {
            loop: true,
            autoplay: {
                delay: 5000,
            },
            speed: 1000,
            slidesPerView: 1,
            loopedSlides: 50,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        });

        /*
		|
		| Slider logo
		|-----------------
		*/
        var swiperslider = new Swiper('.swiper-logo', {
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            loop: true,
            speed: 1000,
            slidesPerView: 1,
            breakpoints: {
                480: {
                    slidesPerView: 1,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 40
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 40
                }
            }

        });

        /*
		|
		| Slider flex
		|-----------------
		*/
        // var swipercontainer = new Swiper('#swiper-container', {
        //     loop: true,
        //     autoplay: {
        //         delay: 5000,
        //     },
        //     speed: 1000,
        //     slidesPerView: 1,
        //     spaceBetween: 15,
        //     loopedSlides: 50,
        //     navigation: {
        //         nextEl: ".swiper-button-next",
        //         prevEl: ".swiper-button-prev"
        //     }
        // });

        /*
		|
		| Slider actus
		|-----------------
		*/
        var swiperactus = new Swiper('.swiper-actus', {
            loop: true,
            autoplay: {
                delay: 5000,
            },
            speed: 1000,
            slidesPerView: 1,
            spaceBetween: 15,
            loopedSlides: 50,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        });

        /*
		|
		| Slider home
		|-----------------
		*/
        var swiperhome = new Swiper('.swiper-home', {
            autoHeight: true, //enable auto height
            loop: true,
            slidesPerView: 1,
            loopedSlides: 50,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        });

        /*
		|
		| Slider chrono
		|-----------------
		*/
        var chronoThumbs = new Swiper('.chrono-thumbs', {
            spaceBetween: 10,
            centeredSlides: true,
            touchRatio: 0.2,
            slideToClickedSlide: true,
            slidesPerView: 5,
            speed: 1000,
            navigation: {
                nextEl: '.swiper-chrono-prev',
                prevEl: '.swiper-chrono-next',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                480: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 3,
                },
                992: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 5,
                }
            }
        });
        var chronoContent = new Swiper('.chrono-content', {
            autoHeight: true, //enable auto height
        });
        var chronoGalerie = new Swiper('.chrono-galerie', {
            autoHeight: true, //enable auto height
            loop: true,
            speed: 1000,
            slidesPerView: 1,
            loopedSlides: 50,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        });

        if (document.getElementsByClassName('chrono-thumbs').length) {
            chronoContent.controller.control = chronoThumbs;
            chronoThumbs.controller.control = chronoContent;
        }


        /*
		|
		| Menu home
		|-----------------
		*/
        jQuery('.menu-sites a').on("mouseover", function () {
            var bgimg = jQuery(this).data("image");
            jQuery(this).parent().css("background-image", 'url(' + bgimg + ')');
        })

        /*
		|
		| Menu scroll
		|-----------------
		*/
        var lastScrollTop = 0;
        $(window).scroll(function () {
            var st = $(this).scrollTop();
            var banner = $('#header');
            if ($(this).scrollTop() > 5) {
                banner.addClass('active');
            } else {
                banner.removeClass('active');
            }
        });

        // $body.on('loaderEnd', () => console.log('ended'))
    }
}
